<template>
  <v-card class="custom-fields-popup-card">
    <v-card-text class="px-0 pb-2">
      <div class="creative__background d-flex align-center justify-center">
        <div class="point-text">
          <v-img
            v-if="imageUrl"
            :src="imageUrl"
          />
          <p v-else>
            Not viewable
          </p>
        </div>
      </div>

      <v-card-actions class="divider-with-shadow d-flex px-6 pb-0">
        <div class="flex-grow-1 filename">
          {{ fileName }}
        </div>
        <v-btn
          height="34px"
          class="elevation-0 custom-button custom-button--blue"
          width="150px"
          @click="$emit('dismiss')"
        >
          Dismiss
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ViewImageDialog",
  props: {
    imageUrl: {
      default: '',
      type: String,
    },
    fileName: {
      default: '',
      type: String,
    },
  },
};
</script>

<style lang="scss">
.creative {
  &__background {
    min-height: 100px;
    background: #a1a1a1;
  }
}
.filename {
  font-weight: 600;
  color: #241c15;
}
</style>
