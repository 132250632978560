<template>
  <v-expansion-panel v-if="isDisplayable">
    <div
      v-if="!hasChildren(folder)"
      class="folder-block d-flex align-center"
      :class="[
        paddingByLevel,
        isSelectedFolder(folder) ? 'folder-block--selected' : ''
      ]"
      @click="$emit('select-folder', folder)"
    >
      <v-icon color="#66788e" size="14" class="mr-2">
        mdi-folder-outline
      </v-icon>
      <div>{{ displayFolderName }}</div>
      <v-spacer />
      <v-icon v-if="isSelectedFolder(folder)" color="#2b84eb" size="16" class="ml-2">
        mdi-check
      </v-icon>
    </div>
    <v-expansion-panel-header
      v-else
      :class="[
        paddingByLevel,
        !hasChildren(folder) ? 'cursor-auto' : '',
      ]"
      class="actions-panel__header-outer"
      hide-actions
      @click="$emit('select-folder', folder)"
    >
      <div class="d-flex align-center">
        <v-icon color="#66788e" size="16" class="mr-1">
          mdi-chevron-right
        </v-icon>
        <div>{{ displayFolderName }}</div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="actions-panel__content-outer">
      <FolderItem
        v-for="(subFolder, index) in folder_structure"
        :key="index"
        :readonly="!Object.keys(subFolder).length"
        :folder="subFolder"
        :folder_structure="folder_structure"
        :selected_folder="selected_folder"
        :selected_folder_name="selected_folder_name"
        :folder-files="folderFiles"
        :folder-name-rule="folder"
        :parent-level="level"
        @select-folder="(childSubFolder) => $emit('select-folder', childSubFolder)"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "FolderItem",
  props: {
    folder: {
      type: String,
      required: true,
    },
    folder_structure: {
      default: () => {},
      type: Array,
    },
    selected_folder: {
      default: () => [],
      type: Array,
    },
    selected_folder_name: {
      default: () => null,
      type: String,
    },
    folderFiles: {
      type: Object,
      required: true,
    },
    folderNameRule: {
      type: String,
      default: "",
    },
    parentLevel: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isDisplayable() {
      // edge case - root
      if (!this.folderNameRule) {
        return this.folder.indexOf("/") < 0
      }

      return this.folder.indexOf(this.folderNameRule) >= 0
          && this.level === (this.parentLevel + 1)
    },
    displayFolderName() {
      // edge case - root
      if (!this.folderNameRule) {
        return this.folder;
      }

      return this.folder.replace(this.folderNameRule + "/", "");
    },
    level() {
      return this.folder.split("/").length;
    },
    paddingByLevel() {
      if (!this.folderNameRule) {
        return "pl-6";
      }

      return "pl-" + (6 * (this.level));
    },
  },
  methods: {
    isSelectedFolder(name) {
      return name === this.selected_folder_name
    },
    hasChildren(folderName) {
      return this.folder_structure.find(item => item !== folderName && item.startsWith(folderName+'/'))
    },
  },
}
</script>

<style scoped>
  .pl-18 {
    padding-left: 72px !important;
  }
  .pl-24 {
    padding-left: 96px !important;
  }
  .pl-30 {
    padding-left: 120px !important;
  }
</style>
