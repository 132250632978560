<template>
  <div>
    <div>
      <v-dialog
        v-model="isInsertImg"
        scrollable
        min-width="500px"
        max-width="1000px"
        @click:outside="$emit('dismiss-modal')"
      >
        <MediaLibraryDialog
          class="p-4"
          style="height: 700px"
          :selectable="true"
          :need-reload="needReload"
          :folder-name-to-be-created="selectedFolderName"
          @add-images="onFileSelected"
          @dismiss="$emit('dismiss-modal')"
          @new-folder="addNewFolder($event)"
          @upload-file="triggerUploadFile($event)"
          @reset-reload="needReload = false"
        />
      </v-dialog>
    </div>
    <div>
      <v-dialog
        v-if="dialogFolder"
        v-model="dialogFolder"
        scrollable
        max-width="600px"
        content-class="new-folder-dialog"
      >
        <v-card class="custom-field-popup-card pt-4">
          <v-card-title class="d-flex align-center justify-space-between px-5 pb-4">
            <div>
              Add New
              <span v-show="newFolderParent">Sub-Folder</span>
              <span v-show="!newFolderParent">Folder</span>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="p-4">
            <v-row class="pl-3">
              <v-col cols="12" class="pa-0">
                <validation-observer ref="addFolderForm">
                  <CustomTextInput
                    v-model="newFolderNameCreate"
                    class="mt-6 mb-6"
                    header="Folder Name"
                    required
                    maxlength="32"
                  />
                </validation-observer>
                <p v-show="newFolderParent">
                  Parent Folder: <strong v-text="newFolderParent" />
                  <v-icon color="red" style="cursor: pointer;" @click="clearAddParent">
                    mdi-close
                  </v-icon>
                </p>
              </v-col>
              <v-col cols="12" class="pa-0 d-flex">
                <v-btn
                  height="34px"
                  class="elevation-0 custom-button custom-button--blue mt-2"
                  width="150px"
                  @click="dialogFolder = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  height="34px"
                  class="elevation-0 custom-button custom-button--blue mt-2 ml-auto"
                  width="150px"
                  @click="addFolder"
                >
                  Add new folder
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <file-upload
        ref="upload"
        v-model="draggedFiles"
        :multiple="true"
        :drop="true"
        :drop-directory="false"
        @input="handleDroppedFile"
      />
      <input
        ref="imageFile"
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .pdf"
        style="display: none;"
        multiple
        @change="handleSelectedFile"
      >
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import FileUpload from 'vue-upload-component';
import MediaLibraryDialog from "@/views/MediaLibrary/components/MediaLibraryDialog";

function Queue() {
  this.items = [];
}
Queue.prototype.enqueue = function (item) {
  this.items.push(item);
}

Queue.prototype.dequeue = function () {
  return this.items.shift();
}

Queue.prototype.hasItems = function () {
  return this.items.length > 0;
}

export default {
  name: "MediaLibraryComponent",
  components: {
    CustomTextInput,
    FileUpload,
    MediaLibraryDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mediaCollections: [],
      folderFiles: {},
      newFolderNameCreate: "",
      newFolderParent: "",
      dialogFolder: false,
      filesUploadQueue: new Queue(),
      draggedFiles: [],
      previewFiles: [], // file that's uploading and will show preview mode
      queueUploadInterval: null,
      isUpload: false,
      needReload: false,
      isInsertImg: false,
      selectedFolderName: null,
    };
  },
  computed: {
    mediaPath() {
      return process.env.VUE_APP_REST_ADDRESS;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.isInsertImg = val;
      }
    },
  },
  mounted() {
    this.queueUploadInterval = setInterval(this.processUploadQueue, 2000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onUpdateSize);
    clearInterval(this.queueUploadInterval);
  },
  methods: {
    addNewFolder(data) {
      this.dialogFolder = true
      this.newFolderNameCreate = ""
      this.newFolderParent = data.selectedFolderName
    },
    triggerUploadFile(data) {
      if (!data.selectedFolderName) {
        return this.$store.commit('snackbar/showMessage', {
          color: "error",
          content: "Please select a folder first."
        });
      }

      this.$refs.imageFile.click();
    },
    onFileSelected(files) {
      this.$emit('add-images', files);
    },
    async processUploadQueue() {
      if (!this.filesUploadQueue.hasItems()) {
        return;
      }
      if (this.isUpload) {
        return;
      }

      const file = this.filesUploadQueue.dequeue();
      await this.uploadFileAPI(file.file, file.folder);

      // remove from preview
      this.previewFiles.splice(
          this.previewFiles.findIndex(item => item.id === file.id),
          1
      );
      this.needReload = false;
    },
    async uploadFileAPI(file, folder) {
      try {
        const formData = new FormData();
        formData.append("mediaFile", file);
        formData.append("directory", folder);

        await this.$rest.media_library.post_resource(formData)
        // add new added file into a correct folder
        this.needReload = true;

        return true;
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      } finally {
        this.$refs.imageFile.value = '';
      }
    },
    handleDroppedFile() {
      if (!this.selectedFolderName) {
        this.draggedFiles = [];

        return this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Select a folder to upload first",
        });
      }

      this.draggedFiles.forEach((fileInfo) => {
        const file = fileInfo.file;
        if (!this.isImageFile(file)) {
          return;
        }

        const id = _.uniqueId('media-file-');

        // prepare the file for review
        this.addFileToPreview(file, id, this.selectedFolderName);

        // upload queue
        this.filesUploadQueue.enqueue({
          file,
          id: id,
          folder: this.selectedFolderName,
        });
      });

      // added all to queue => clear stuff
      this.draggedFiles = [];
    },
    addFileToPreview(file, fileId, folder) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewFiles.push({
          id: fileId,
          result: reader.result,
          folder: folder,
          name: file.name,
          size: file.size,
        });
      }

      reader.readAsDataURL(file);
    },
    clearAddParent() {
      this.newFolderParent = ""
    },
    async addFolder() {
      if (!(await this.$refs.addFolderForm.validate())) {
        return;
      }

      const folderNameToBeCreated = this.newFolderParent
          ? this.newFolderParent + "/" + this.newFolderNameCreate
          : this.newFolderNameCreate

      try {
        const formData = new FormData();
        formData.append("directory", folderNameToBeCreated);
        await this.$rest.media_library.post_resource_directory(formData)

        this.mediaCollections.push(folderNameToBeCreated);

        this.$store.commit("snackbar/showMessage", {
          color: "success",
          content: "Folder created",
        });

        this.dialogFolder = false;

        // select folder after created
        this.select_folder(folderNameToBeCreated);
        this.selectedFolderName = folderNameToBeCreated
        this.needReload = true;
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Failed to create new folder",
        });
      }
    },
    async select_folder(name) {
      // load file
      if (!this.folderFiles[name]) {
        const folderFiles = [];
        const result = await this.$rest.media_library.get_collection_directory({
          directory: name
        });
        (result.data || []).forEach(item => {

          // check file or folder
          if (item.size) {
            // file
            folderFiles.push({
              ...item,
              fileUrl: this.mediaPath.concat(item.path),
            })
          } else {
            // folder
            const itemWithoutRootPath = this.selectedFolderName + '/' + item.basename;
            this.mediaCollections.push(itemWithoutRootPath);
          }
        });

        // set shortage cache
        this.$set(this.folderFiles, name, folderFiles);
      }

      this.selected_folder = [...this.folderFiles[name]]
    },
    isImageFile(file) {
      return ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'].indexOf(file.type) >= 0;
    },
    handleSelectedFile(event) {
      Object.values(event.target.files).forEach(file => {
        //limit 3 mb
        if (file.size > 3*1024*1024) {
          this.$store.commit("snackbar/showMessage", {
            content: "The file size should be a less then 3MB",
            color: "error"
          });

          this.$refs.imageFile.value = '';
          return;
        }

        if (!this.isImageFile(file)) {
          this.$store.commit("snackbar/showMessage", {
            content: "”We only accept the following file types: .jpg, .jpeg, .png, .gif, pdf",
            color: "error"
          });

          this.$refs.imageFile.value = '';
          return;
        }

        const id = _.uniqueId('media-file-');

        // prepare the file for review
        this.addFileToPreview(file, id, this.selectedFolderName);

        // upload queue
        this.filesUploadQueue.enqueue({
          file,
          id: id,
          folder: this.selectedFolderName,
        });
      });
    },
  },
};
</script>
