<template>
  <div class="view-media">
    <div class="media-folders__header d-flex">
      {{ selected_folder_name || 'Select a folder' }}
      <v-btn
        v-if="selected_folder_name && $store.getters['user/hasWritePermission']"
        class="custom-button custom-button--gray px-10 ml-auto"
        height="34px"
        depressed
        @click="delete_folder_modal = true"
      >
        Delete
      </v-btn>
    </div>

    <div v-if="!folder_content" class="view-media__empty-content">
      Select a folder
    </div>
    <div
      v-else-if="folder_content && !folder_content.length && !previewImagesOfSelectedFolder.length"
      class="view-media__empty-content"
    >
      <span v-if="selected_folder_name">
        This folder is empty
      </span>
      <span v-else>
        Select a folder
      </span>
    </div>
    <div
      v-else-if="(folder_content.length || previewImagesOfSelectedFolder.length) && view_value === 'grid'"
      class="view-media__content media-grid d-flex flex-wrap"
    >
      <div
        v-for="(item, i) in sorted_items_list"
        :key="i"
        :class="{'selected': isSelected(item.path)}"
        class="view-media__image-item"
      >
        <div v-if="isPdfFile(item)" class="grid-image">
          <v-icon size="120">
            mdi-file-pdf-box
          </v-icon>
        </div>
        <img
          v-else
          class="grid-image"
          :src="item.path"
          @click="viewOriginal(item.path)"
        >
        <div class="option">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="black"
                style="cursor: pointer;"
                v-on="on"
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                :href="item.path"
                link
                target="_blank"
              >
                <v-list-item-title>Open File</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-clipboard="item.path"
                link
                target="_blank"
              >
                <v-list-item-title>Copy URL to clipboard</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$store.getters['user/hasWritePermission']"
                link
                target="_blank"
                @click="deleteAsset(item.basename)"
              >
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <span v-show="isSelected(item.path)" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g fill="none" fill-rule="evenodd">
              <g>
                <g>
                  <g fill="#2B84EB" transform="translate(-1201 -263) translate(1201 263)">
                    <circle cx="8.5" cy="8.5" r="8.5" />
                  </g>
                  <g fill="#FFF" fill-rule="nonzero">
                    <path d="M1.102 3.423c-.223-.238-.585-.238-.808 0-.223.238-.223.624 0 .862L2.74 6.894c.224.238.585.238.808 0l5.38-5.74c.223-.239.223-.624 0-.863-.223-.238-.585-.238-.808 0l-4.976 5.31-2.04-2.178z" transform="translate(-1201 -263) translate(1201 263) translate(4.25 5.1)" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>
      <div
        v-for="(item, i) in previewImagesOfSelectedFolder"
        :key="i"
        class="preview-image"
      >
        <img
          class="grid-image"
          :src="item.result"
        >
        <v-icon class="icon mdi-spin" size="30">
          mdi-loading
        </v-icon>
      </div>
    </div>
    <div
      v-else-if="(folder_content.length || previewImagesOfSelectedFolder.length) && view_value === 'list'"
    >
      <div
        v-for="(item, i) in sorted_items_list"
        :key="i"
        class="view-media__list"
        :class="{'selected': isSelected(item.path)}"
        @click="viewOriginal(item.path)"
      >
        <div class="media-list-item">
          <img
            :src="item.path"
            alt=""
            class="media-list-item__image d-flex align-center"
          >
          <div class="d-flex align-center bold-text">
            {{ item.basename }}
          </div>
          <div class="d-flex align-center" :title="getDateWithDayNameAndTime(item.timestamp * 1000)">
            added {{ getDate(item.timestamp * 1000) }}
          </div>
          <div class="d-flex align-center">
            {{ (item.size / 1024 / 1000).toFixed(1) }}MB
          </div>
          <div v-show="isSelected(item.path)" class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
              <g fill="none" fill-rule="evenodd">
                <g>
                  <g>
                    <g fill="#2B84EB" transform="translate(-1201 -263) translate(1201 263)">
                      <circle cx="8.5" cy="8.5" r="8.5" />
                    </g>
                    <g fill="#FFF" fill-rule="nonzero">
                      <path d="M1.102 3.423c-.223-.238-.585-.238-.808 0-.223.238-.223.624 0 .862L2.74 6.894c.224.238.585.238.808 0l5.38-5.74c.223-.239.223-.624 0-.863-.223-.238-.585-.238-.808 0l-4.976 5.31-2.04-2.178z" transform="translate(-1201 -263) translate(1201 263) translate(4.25 5.1)" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="isShowingDialog"
      scrollable
      max-width="1024px"
    >
      <ViewImageDialog
        :image-url="viewingImageUrl"
        :file-name="getFileName(viewingImageUrl)"
        @dismiss="val => isShowingDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="isShowingConfirmDelete"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmDelete"
        header="Delete media file"
        action_button_name="Confirm"
        @delete="confirmDeleteAsset"
        @dismiss="isShowingConfirmDelete = false;"
      >
        <template #subheader>
          Do you really want to delete this file?<br>
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>
    <v-dialog
      v-model="delete_folder_modal"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="delete_folder_modal"
        header="Delete media folder"
        action_button_name="Confirm"
        @delete="deleteFolder"
        @dismiss="delete_folder_modal = false;"
      >
        <template #subheader>
          Do you really want to delete this folder?<br>
          All assets inside this folder will be deleted
        </template>
      </DeleteConfirmationDialog>
    </v-dialog>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import ViewImageDialog from "@/views/MediaLibrary/components/ViewImageDialog";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "ViewMedia",
  components: {
    ViewImageDialog,
    DeleteConfirmationDialog
  },
  mixins: [datesMixin],
  props: {
    folder_content: {
      default: () => [],
      type: Array,
    },
    selected_folder_name: {
      default: () => null,
      type: String,
    },
    sort_by_value: {
      default: () => 'size',
      type: String,
    },
    view_value: {
      default: () => 'grid',
      type: String,
    },
    previewFiles: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      delete_folder_modal: false,
      isShowingDialog: false,
      viewingImageUrl: "",
      selectedFiles: [],
      isShowingConfirmDelete: false,
      deleteAssetFile: null,
    };
  },
  computed: {
    sorted_items_list() {
      if (this.sort_by_value === 'size') {
        return [...this.folder_content].sort((a, b) => a.size - b.size)
      } else if (this.sort_by_value === 'date') {
        return [...this.folder_content].sort((a, b) => new Date(b.timestamp * 1000) - new Date(a.timestamp * 1000))
      }

      return this.folder_content
    },
    previewImagesOfSelectedFolder() {
      return this.previewFiles.filter(item => item.folder === this.selected_folder_name);
    },
  },
  created() {
  },
  methods: {
    deleteAsset(asset) {
      this.deleteAssetFile = asset;
      this.isShowingConfirmDelete = true;
    },
    async confirmDeleteAsset() {
      try {
        await this.$rest.media_library.delete_resource_asset({
          directory: "/" + this.selected_folder_name,
          mediaFile: this.deleteAssetFile,
        });

        this.$store.commit("snackbar/showMessage", {
          color: "info",
          content: `Deleted asset ${this.deleteAssetFile}`,
        });

        this.$emit("media-updated");
        this.isShowingConfirmDelete = false;
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: `Delete asset failed. Please try again later`,
        });
      }
    },
    async deleteFolder() {
      try {
        await this.$rest.media_library.delete_resource_directory({
          directory: "/" + this.selected_folder_name,
        });
        this.$store.commit("snackbar/showMessage", {
          color: "info",
          content: `Deleted folder ${this.selected_folder_name} success`,
        });
        this.$emit("folder-deleted");
        this.delete_folder_modal = false;
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: `Delete media folder failed. Please try again later`,
        });
      }
    },
    getFileName(url) {
      if (!url) {
        return '';
      }
      const parts = url.split("/");
      return parts[parts.length - 1];
    },
    viewOriginal(imageUrl) {
      if (this.selectable === false) {
        this.isShowingDialog = true;
        this.viewingImageUrl = imageUrl;
      } else {
        const fileIndex = this.selectedFiles.indexOf(imageUrl);
        if (fileIndex >= 0) {
          this.selectedFiles.splice(fileIndex, 1);
        } else {
          this.selectedFiles.push(imageUrl);
        }

        this.$emit('selected-files', this.selectedFiles);
      }
    },
    isSelected(imageUrl) {
      return this.selectedFiles.indexOf(imageUrl) >= 0;
    },
    clearSelected() {
      this.selectedFiles = [];
    },
    isPdfFile(file) {
      const extension = file.basename.split('.').pop();

      return extension === 'pdf';
    }
  },
};
</script>

<style lang="scss">
.view-media {
  border-radius: 5px;
  border: solid 1px #dee7ee;

  &__content {
    border-top: solid 1px #dee7ee;
    .selected {
      background-color: rgba(43, 132, 235, 0.08);
      opacity: .5;
      position: relative;
    }

    .icon {
      opacity: 1;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  &__empty-content {
    border-top: solid 1px #dee7ee;
    padding: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #66788e;
  }

  .media-grid {
    padding: 18px;
    cursor: pointer;

    .grid-image {
      width: 128px;
      height: 128px;
      margin: 6px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .media-list-item {
    display: grid;
    grid-template-columns: 46px 1fr 130px 50px;
    grid-column-gap: 14px;
    padding: 12px 22px;
    border-top: solid 1px #dee7ee;

    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #241c15;

    position: relative;

    &__image {
      height: 46px;
      width: 46px;
      border-radius: 3px;
      object-fit: cover;
    }

    .icon {
      opacity: 1;
      position: absolute;
      right: 5px;
      top: 25px;
    }
  }
  &__list.selected {
    background-color: rgba(43, 132, 235, 0.08);
  }
  .bold-text {
    font-weight: 600;
  }

  .preview-image {
    opacity: .5;
    position: relative;

    .icon {
      position: absolute;
      top: 40%;
      left: 40%;
      opacity: 1;
      color: black;
    }
  }

  &__image-item {
    position: relative;

    .option {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 2;

      :hover {
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
  }
}
</style>
