import { render, staticRenderFns } from "./FolderItem.vue?vue&type=template&id=a2e045f6&scoped=true"
import script from "./FolderItem.vue?vue&type=script&lang=js"
export * from "./FolderItem.vue?vue&type=script&lang=js"
import style0 from "./FolderItem.vue?vue&type=style&index=0&id=a2e045f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2e045f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VSpacer})
