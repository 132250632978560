<template>
  <div class="media-folders">
    <div class="media-folders__header">
      Media
    </div>
    <v-expansion-panels flat multiple accordion>
      <FolderItem
        v-for="(folder, index) in folder_structure"
        :key="index"
        :readonly="!Object.keys(folder).length"
        :folder="folder"
        :folder_structure="folder_structure"
        :selected_folder="selected_folder"
        :selected_folder_name="selected_folder_name"
        :folder-files="folderFiles"
        @select-folder="(folder, name) => $emit('select-folder', folder)"
      />
    </v-expansion-panels>
    <div
      v-if="!folder_structure || Object.keys(folder_structure).length === 0"
      class="view-media__empty-content"
    >
      Empty
    </div>
  </div>
</template>

<script>
import FolderItem from "@/views/MediaLibrary/components/FolderItem";

export default {
  name: "MediaFolderStructure",
  components: {
    FolderItem
  },
  props: {
    folder_structure: {
      default: () => {},
      type: Array,
    },
    selected_folder: {
      default: () => [],
      type: Array,
    },
    selected_folder_name: {
      default: () => null,
      type: String,
    },
    folderFiles: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  computed: {},
  created() {
  },
  methods: {
    isSelectedFolder(name) {
      return name === this.selected_folder_name
    },
    hasChildren(folderName) {
      return this.folder_structure.find(item => item !== folderName && item.startsWith(folderName))
    },
  },
};
</script>

<style lang="scss">
.media-folders {
  border-radius: 5px;
  border: solid 1px #dee7ee;

  &__header {
    padding: 20px 24px 14px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #241c15;
  }

  .v-expansion-panel-header.actions-panel__header-outer {
    border-top: solid 1px #dee7ee;
    border-radius: 0;
    min-height: 48px;
    padding-left: 23px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #241c15;
  }

  .actions-panel__content-outer > .v-expansion-panel-content__wrap {
    padding: 0;
    > .v-item-group {
      border-top: solid 1px #dee7ee;
    }
    > .v-item-group {
      justify-content: flex-start;
    }
  }
  // .v-expansion-panel-header.actions-panel__header-outer:last-child {
  //   border-bottom: solid 1px #dee7ee;
  // }
  .v-expansion-panel-header.actions-panel__header-inner {
    min-height: 48px;
    padding-left: 42px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #241c15;
  }

  .actions-panel__content-inner > .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .actions-panel__header-outer.v-expansion-panel-header--active > div > .v-icon {
    transform: rotate(90deg);
  }
  .actions-panel__header-inner.v-expansion-panel-header--active > div > .v-icon {
    transform: rotate(90deg);
  }

  .folder-block {
    border-top: solid 1px #dee7ee;
    height: 48px;
    padding-left: 15px;
    padding-right: 26px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #241c15;
    cursor: pointer;
    width: 100%;
    &--selected {
      color: #2b84eb !important;
      .v-icon {
        color: #2b84eb !important;
      }
    }
    &--no-border {
      border-top: none;
    }
  }

  .cursor-auto {
    cursor: auto;
  }
}
</style>
