<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader header-text="Edit Email Creative">
      <template #subheader>
        <div>Update the following information and select “Save”</div>
      </template>
    </PageHeader>

    <v-row class="mt-3">
      <v-col cols="12">
        <CustomTextInput
          v-model="emailMessage.name"
          required
          header="Name"
          placeholder="Enter a name for this Email Creative"
        />
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <TagsInput
          v-model="emailTags"
          :items="tagNames"
          header="Tags"
          placeholder="Select / Add Tag(s) for this Creative"
        />
      </v-col>
    </v-row>

    <div class="flex-fill mt-5">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue mr-2"
        @click="sendTestEmail"
      >
        <v-icon size="18" color="#ffffff" class="mr-2">
          mdi-email
        </v-icon>
        Send Test
      </v-btn>
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue mr-2"
        @click="openInsertMedia"
      >
        <v-icon size="18" color="#ffffff" class="mr-2">
          mdi-file-image
        </v-icon>
        Insert Media
      </v-btn>
      <ChoiceSelector
        :value="lastSelectedPlaceholder"
        :items="placeholders"
        placeholder-text="Placeholder"
        style="display: inline-flex"
        @input="addPlaceholder($event)"
      >
        <template #button="slotProps">
          <v-btn
            height="34px"
            class="elevation-0 custom-button custom-button--blue mr-2"
            v-bind="slotProps"
            v-on="slotProps"
          >
            Placeholder
            <v-icon size="18" color="#ffffff" class="ml-2">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
      </ChoiceSelector>
    </div>

    <v-divider class="pt-4 pb-4" />

    <div class="creative__background d-flex align-center justify-center">
      <div class="point-text">
        <div style="background-color: white" :style="{ width }" class="row ma-0">
          <div id="editor" class="col-6 pa-0" style="height: 600px" />
          <iframe
            ref="preview-section"
            class="col-6 pa-0 w-100 pl-2"
            style="height: 600px; overflow-y: auto; border:none;"
            :srcdoc="replacePersonalizationFields(data)"
          />
        </div>
      </div>
    </div>

    <div class="flex-fill d-flex justify-end px-6 pb-0 pt-4">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="beforeSave()"
      >
        Save
      </v-btn>
    </div>
    <v-dialog v-model="isSendingTest" max-width="500px">
      <SendTestEmailDialog :email-creative="emailMessage" @dismiss="isSendingTest = false" />
    </v-dialog>
    <MediaLibraryComponent v-model="isInsertingImage" @add-images="onFileSelected" @dismiss-modal="isInsertingImage = false" />
    <v-dialog
      v-model="confirmDialog"
      scrollable
      max-width="360px"
    >
      <DeleteConfirmationDialog
        v-if="confirmDialog"
        header="Save creative"
        subheader="Do you really want to save creative without the Pulse unsubscribe link?"
        action_button_name="Confirm"
        @delete="on_save()"
        @dismiss="confirmDialog = false;"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SendTestEmailDialog from "@/views/Campaigns/components/EmailCreatives/SendTestEmailDialog";
import TagsInput from "@/sharedComponents/TagsInput";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import { hasError } from "@/mixins/has_error";
import ace from 'ace-builds/src-min-noconflict/ace'
import 'ace-builds/webpack-resolver'
import ChoiceSelector from "../Contacts/components/inputs/ChoiceSelector";
import MediaLibraryComponent from "@/views/MediaLibrary/components/MediaLibraryComponent";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "EditEmailCreativeView",
  components: {
    MediaLibraryComponent,
    ChoiceSelector,
    CustomTextInput,
    TagsInput,
    SendTestEmailDialog,
    PageHeader,
    DeleteConfirmationDialog,
  },
  mixins: [hasError],
  data() {
    return {
      editor: '',
      data: '<!doctype html>\n' +
        '<html>\n' +
        ' <head>\n' +
        '   <meta name="viewport" content="width=device-width">\n' +
        '   <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
        '   <title></title>\n' +
        '   <style></style>\n' +
        ' </head>\n' +
        ' <body>\n' +
        '\n<!-- INSERT CONTENT HERE -->\n\n' +
        ' </body>\n' +
        '</html>\n',
      width: '900px',
      height: '900px',
      placeholders: [],
      emailMessage: {},
      isInsertingImage: false,
      isSendingTest: false,
      isHTML: true,
      tags: [],
      tagNames: [],
      emailTags: [],
      lastSelectedPlaceholder: null,
      confirmDialog: false,
    };
  },
  watch: {
    data() {
      // Set time out to avoid trigger on every key stroke
      // Also to wait for DOM to update correctly
      setTimeout(() => {
        const aTags = this.$refs["preview-section"]?.contentWindow?.document?.getElementsByTagName("a");
        if (aTags.length == 0) return;

        [...aTags].forEach(aTag => {
          if(
            aTag.getAttribute("href") == "{{ unsubscribeLink }}" ||
            aTag.getAttribute("href") == "{{ viewInBrowserLink }}" ||
            aTag.getAttribute("href") == "{{ changeContactDetailsLink }}"
          ) {
            aTag.removeAttribute("href");
            aTag.addEventListener("click", () => {
              this.$store.commit('snackbar/showMessage', {
                content: 'Viewing this link is not possible from this page.',
                color: 'error',
              });
            });
          }
          aTag.setAttribute("target", "_blank");
        });
      }, 500);
    }
  },
  async created() {
    // get email
    await this.getEmailMessage();
    this.getTags();
    this.getTagsOfEmail();
  },
  mounted() {
    this.editor = ace.edit('editor', {
      showPrintMargin: false,
      mode: 'ace/mode/html',
      theme: 'ace/theme/twilight',
    });
    this.editor.setValue(this.data);
    this.editor.session.on('change', () => {
      this.data = this.emailMessage.htmlContent = this.editor.getValue();
    });

    // width
    this.onUpdateSize();
    window.addEventListener('resize', this.onUpdateSize);

    this.getPersonalizeFields();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onUpdateSize);
  },
  methods: {
    async getEmailMessage() {
      try {
        const result = await this.$rest.creative.email.get_resource(this.$route.params.id, {
          includeHtmlContent: true,
        });
        this.emailMessage = { ...result.data };

        if (this.emailMessage.htmlContent) {
          this.editor.setValue(this.emailMessage.htmlContent);
        } else {
          this.emailMessage.htmlContent = this.data;
        }
      } catch (e) {
        this.$store.commit('snackbar/showMessage', {
          color: 'error',
          content: 'Email Message not found',
        });
        this.$router.push({
          name: 'EmailCreatives',
        });
      }
    },
    async beforeSave() {
      const regex = /\{\{\s*unsubscribeLink\s*\}\}/g;
      const match = this.data.match(regex);

      if (match) {
        await this.on_save();
      } else {
        this.confirmDialog = true
      }
    },
    async on_save() {
      const updateData = {
        ...this.emailMessage,
        htmlContent: this.data,
        tagItems: this.emailTags.map(tagName => {
          const tagInstance = this.tags.find(
            tagItem => tagItem.name.toLowerCase() === tagName.toLowerCase()
          );

          return tagInstance ? {id: tagInstance.id} : {name: tagName};
        }),
      };

      try {
        const response = await this.$rest.creative.email.put_resource(this.emailMessage.id, updateData);
        if (!response.data) {
          return;
        }
      } catch (e) {

        const errorResponse = e.response?.data ?? null;

        if (errorResponse) {
          let errors = '';

          if (errorResponse?.errors) {
            errorResponse.errors.forEach(item => {
              errors += (item.error + "\r \n");
            });
          } else if (errorResponse.message) {
            errors = errorResponse.message;
          }

          return this.$store.commit('snackbar/showMessage', {
            'content': errors ? errors : 'An unexpected error occurred.',
            'color': 'error',
          });
        }
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Saved successfully!',
        color: 'info',
      });
      this.$router.push({
        name: 'EmailCreatives',
      });
    },
    async getPersonalizeFields() {
      const params = new URLSearchParams();
      params.append("includeSystemLinks", true);
      const { data } = await this.$rest.personalization_fields.getCollection(params);
      this.placeholders = [...data.map(item => ({
        value: `{{ ${item.personalizationKey} }}`,
        label: item.isSystemLink ? item.field : `${item.entity} - ${item.field}`,
      }))];
    },
    onUpdateSize() {
      this.width = window.innerWidth - 200 + 'px';
      this.height = window.innerHeight - 10 + 'px';
    },
    sendTestEmail() {
      this.isSendingTest = true;
    },
    addPlaceholder(placeholder) {
      if (placeholder === undefined) {
        // The value is undefined in cases where the user selects the same placeholder
        // more than once in a row. To handle this, we will keep track of the last selected
        // placeholder and if the user selects it again, we will add it to the HTML again
        placeholder = this.lastSelectedPlaceholder;
      } else {
        this.lastSelectedPlaceholder = placeholder;
      }

      const position = this.editor.getCursorPosition();
      this.editor.session.insert(position, placeholder);

      this.editor.focus();
      this.editor.moveCursorTo(position.row, position.column + placeholder.length);
    },
    onFileSelected(files) {
      for (const fileUrl of files) {
        this.addPlaceholder(`<img src="${fileUrl}" />`);
      }
    },
    openInsertMedia() {
      this.isInsertingImage = true;
    },
    async getTags() {
      const response = await this.$rest.tags
        .getCollection({
          fields: ['id', 'name'],
          ignorePagination: true,
        })
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.tags = response.data.items;
      this.tagNames = this.tags.map(tagItem => tagItem.name);
    },
    async getTagsOfEmail() {
      const response = await this.$rest.creative.email
        .getTags(this.emailMessage.id)
        .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.emailTags = response.data.items.map(emailTag => emailTag.tag.name);
    },
    replacePersonalizationFields(html) {
      let replacedHtml = html;
      let isFoundTwig = true;

      while (replacedHtml && isFoundTwig) {
        const beginTwigPos = replacedHtml.indexOf('{{');
        const endTwigPos = replacedHtml.indexOf('}}', beginTwigPos);

        if (beginTwigPos < 0 || endTwigPos < 0) {
          isFoundTwig = false;
          continue;
        }
        const foundTwigField = replacedHtml.slice(beginTwigPos, endTwigPos + 2);
        let replacedField = foundTwigField.replace('{{', '').replace('}}', '').replace('.', ' - ');

        replacedField = replacedField.split(' ').map(word => {
          if (word !== '-') {
            const result = word.replace(/([A-Z])/g, " $1");

            return result.charAt(0).toUpperCase() + result.slice(1);
          }
          return word;
        }).join(' ');
        replacedHtml = replacedHtml.replace(foundTwigField, replacedField);
      }

      return replacedHtml;
    }
  },
};
</script>
