<template>
  <div class="media-control-selector">
    <v-menu transition="slide-y-transition" bottom offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          block
          class="elevation-0 px-2"
          height="30px"
          color="#fff"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="selected_item.icon" color="#dee7ee" size="20" class="mr-1">
            {{ selected_item.icon }}
          </v-icon>
          {{ selected_item.label }}
          <v-icon right color="#4c4c4c">
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="media-control-selector-menu">
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="$emit('select', item.value)"
          >
            <div class="media-option d-flex align-center">
              <v-icon v-if="item.icon" color="#757885" size="20" class="mr-1">
                {{ item.icon }}
              </v-icon>
              <div>{{ item.label }}</div>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "MediaControlSelector",
  props: {
    items: {
      default: () => [],
      type: Array,
    },
    value: {
      default: () => null,
      type: String,
    },
  },
  computed: {
    selected_item() {
      return this.items.find(item => item.value === this.value)
    },
  },
  mounted(){
  },
};
</script>

<style lang="scss">
.media-control-selector {
  button {
    min-width: 115px !important;
    border-radius: 4px;
    border: solid 1px #dee7ee !important;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    span {
      color: #757885;
      font-size: 12px;
    }
    &:before {
      opacity: 0 !important;
    }
    i {
      margin-top: 2px;
    }
    .v-btn__content {
      justify-content: space-between;
    }
  }
}
.media-control-selector-menu {
  .v-list-item {
    min-height: 37px;
    max-height: 37px;
    padding: 0 9px;
  }
  

  .media-option {
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    color: #4c4c4c;
    font-size: 12px;
  }
}
</style>
