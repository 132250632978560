<template>
  <v-card class="custom-field-popup-card audit-history-popup media-library-dialog-content">
    <v-card-title class="d-flex align-center justify-space-between px-5 pt-4 pb-4">
      <div>Media Library</div>
      <MediaControlPanel
        :sort_by_value="sort_by_value"
        :view_value="view_value"
        @change-view="view_value = $event"
        @change-sorting="sort_by_value = $event"
      />
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <v-row class="pl-3 pt-3">
        <v-col cols="8" />
        <v-col cols="2">
          <v-btn
            height="34px"
            class="elevation-0 custom-button custom-button--blue"
            width="130px"
            @click="$emit('new-folder', { selectedFolderName: selectedFolderName })"
          >
            NEW FOLDER
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            height="34px"
            class="elevation-0 custom-button custom-button--gray"
            width="130px"
            @click="$emit('upload-file', { selectedFolderName: selectedFolderName })"
          >
            UPLOAD FILE
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pl-3">
        <v-col cols="12" sm="4" class="pa-0">
          <MediaFolderStructure
            :folder_structure="mediaCollections"
            :selected_folder="selected_folder"
            :selected_folder_name="selectedFolderName"
            :folder-files="folderFiles"
            @select-folder="(folder, name) => select_folder(folder, name)"
          />
        </v-col>
        <v-col cols="12" sm="8" class="pa-0 pr-4">
          <ViewMedia
            ref="viewMediaRef"
            :folder_content="folderFiles[selectedFolderName]"
            :selected_folder_name="selectedFolderName"
            :sort_by_value="sort_by_value"
            :view_value="view_value"
            :selectable="selectable"
            @selected-files="onSelectedFiles"
            @media-updated="reloadFolder"
            @folder-deleted="selectedFolderName = null; loadRoot()"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="shadow d-flex justify-end px-6 pb-3 pt-3">
      <v-btn
        v-show="selectedFiles.length"
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="addImage"
      >
        Add Images
      </v-btn>
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import MediaControlPanel from "@/views/MediaLibrary/components/MediaControlPanel";
import MediaFolderStructure from "@/views/MediaLibrary/components/MediaFolderStructure";
import ViewMedia from "@/views/MediaLibrary/components/ViewMedia";

export default {
  name: "MediaLibraryDialog",
  components: {
    MediaControlPanel,
    MediaFolderStructure,
    ViewMedia
  },
  props: {
    folder_structure: {
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    needReload: {
      type: Boolean,
      default: false,
    },
    folderNameToBeCreated: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      sort_by_value: 'size',
      view_value: 'grid',
      selected_folder: null,
      selectedFolderName: null,
      isAddFolder: false,
      newFolderName: "",
      folderFiles: {},
      mediaCollections: null,
      selectedFiles: []
    };
  },
  computed: {
    rootPath() {
      return this.$store.getters["user/account"].accountId + "/media-library/"
    },
    mediaPath() {
      return process.env.VUE_APP_REST_ADDRESS;
    },
  },
  watch: {
    needReload: function (val) {
      if (val) {
        if (this.folderNameToBeCreated.length > 0 && !this.folderFiles[this.folderNameToBeCreated]) {
          this.mediaCollections.push(this.folderNameToBeCreated);
          this.select_folder(this.folderNameToBeCreated);
        }
        this.reloadFolder();
        this.$emit('reset-reload');
      }
    },
  },
  created() {
    this.loadRoot();
  },
  methods: {
    async reloadFolder() {
      const result = await this.$rest.media_library.get_collection_directory({
        directory: this.selectedFolderName
      });

      const folderFiles = [];
      (result.data || []).forEach(item => {
        // check if file
        if (item.size) {
          folderFiles.push({...item})
        }
      });

      this.$set(this.folderFiles, this.selectedFolderName, folderFiles);
    },
    async select_folder(name) {
      this.selectedFolderName = name

      // load file
      if (!this.folderFiles[name]) {
        const folderFiles = [];
        const result = await this.$rest.media_library.get_collection_directory({
          directory: name
        });
        (result.data || []).forEach(item => {

          // check file or folder
          if (item.size) {
            // file
            folderFiles.push({
              ...item,
              fileUrl: this.mediaPath.concat(item.path),
            })
          } else {
            // folder
            const itemWithoutRootPath = this.selectedFolderName + '/' + item.basename;
            this.mediaCollections.push(itemWithoutRootPath);
          }
        });

        // set shortage cache
        this.$set(this.folderFiles, name, folderFiles);
      }

      this.selected_folder = [...this.folderFiles[name]];
    },
    loadRoot() {
      this.folderFiles = {};
      this.$rest.media_library.get_collection().then(result => {
        const folders = [];

        result.data.forEach(item => {
          folders.push(item.basename);
        });

        this.$set(this, 'mediaCollections', folders);
      });
    },
    onSelectedFiles(files) {
      this.selectedFiles = [
        ...files
      ];
    },
    addImage() {
      this.$emit('add-images', this.selectedFiles);

      // then clear selected
      this.selectedFiles = [];
      this.$refs.viewMediaRef.clearSelected();
      this.$emit('dismiss');
    }
  },
};
</script>

<style lang="scss">
.media-library-dialog {
  height: 90%;

  .media-library-dialog-content {
    .v-card__text {
      overflow-x: hidden;

      .view-media, .media-folders {
        border-radius: 0;
      }
    }
  }
}
</style>
