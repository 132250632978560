<template>
  <div class="custom-text-area">
    <div :class="headerClass || 'field__subheader d-flex pb-2'">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
      <slot name="icon" />
    </div>
    <div v-if="subheader" class="field__subheader pt-2 pb-2">
      {{ subheader }}
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          max: maxlength || false,
          min: minlength || false,
          required,
        }"
        mode="eager"
      >
        <v-textarea
          :placeholder="placeholder"
          :disabled="disabled"
          filled
          solo
          flat
          no-resize
          :error-messages="errors"
          :height="height"
          background-color="#f9f9f9"
          :value="value"
          hide-details="hideDetails"
          @input="(text) => $emit('input', text)"
          @change="(text) => $emit('change', text)"
        />
        <v-btn
          v-if="copy_button"
          class="absolute-copy-button"
          icon
          small
          @click="$emit('copy')"
        >
          <v-icon size="14">
            $copy
          </v-icon>
        </v-btn>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "CustomTextareaV2",
  props: {
    value: {
      default: () => null,
      type: String,
    },
    hideDetails: {
      default: undefined,
      type: Boolean,
    },
    placeholder: {
      default: () => null,
      type: String,
    },
    height: {
      default: () => null,
      type: Number,
    },
    disabled: {
      default: () => false,
      type: Boolean,
    },
    copy_button: {
      default: () => false,
      type: Boolean,
    },
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    subheader: {
      default: () => {},
      type: String,
    },
    maxlength: {
      default: () => {},
      type: [String, Number],
    },
    minlength: {
      default: () => {},
      type: [String, Number],
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-text-area {
  position: relative;

  ::v-deep .v-textarea {
    .v-input__control {
      .v-input__slot {
        padding: 14px !important;
        margin: 0;
        border-radius: 0;
        .v-text-field__slot {
          textarea {
            padding: 0;
            margin: 0;

            font-family: "Open Sans", sans-serif;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #66788e;
          }
        }
      }
    }
  }

  .absolute-copy-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .field__subheader--required {
    color: #e74c3c;
  }
}
</style>
