<template>
  <div class="d-flex">
    <MediaControlSelector
      :items="sort_by_options"
      :value="sort_by_value"
      class="mr-3"
      @select="$emit('change-sorting', $event)"
    />
    <MediaControlSelector
      :items="view_options"
      :value="view_value"
      @select="$emit('change-view', $event)"
    />
  </div>
</template>

<script>
import MediaControlSelector from "@/views/MediaLibrary/components/MediaControlSelector";

export default {
  name: "MediaControlPanel",
  components: {
    MediaControlSelector
  },
  props: {
    sort_by_value: {
      default: () => 'size',
      type: String,
    },
    view_value: {
      default: () => 'grid',
      type: String,
    },
  },
  data() {
    return {
      sort_by_options: [
        {
          label: 'Sort by size',
          value: 'size'
        },
        {
          label: 'Sort by date',
          value: 'date'
        },
      ],
      view_options: [
        {
          icon: 'mdi-view-grid',
          label: 'Grid View',
          value: 'grid'
        },
        {
          icon: 'mdi-format-list-bulleted',
          label: 'List View',
          value: 'list'
        },
      ],
    };
  },
  computed: {},
  created() {
  },
  methods: {
  },
};
</script>
